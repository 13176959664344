import React from 'react';
import PropTypes from 'prop-types';
import SectionIntro from '../SectionIntro';

const StatisticsSection = ({ title, subtitle, statsHeader, items }) => {
  return (
    <section className="statistics-section">
      <div className="statistics-section__intro">
        <SectionIntro title={title} subtitle={subtitle} />
      </div>

      <div className="statistics-section__body">
        <h3 className="statistics-section__subtitle">{statsHeader}</h3>
        <div className="statistics-section__items">
          {items.map(({ itemTitle, value }, i) => (
            <div
              key={`statistics-section__item${i}`}
              className="statistics-section__item"
            >
              <div className="statistics-section__item-value">{value}</div>
              <h4 className="statistics-section__item-title">{itemTitle}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="statistics-section__line-decoration"></div>
    </section>
  );
};

StatisticsSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  statisticsTitle: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

StatisticsSection.defaultProps = {
  title: '',
  body: '',
  statisticsTitle: '',
  items: [],
};

export default StatisticsSection;
