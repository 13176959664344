import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import MediaHero from '../components/MediaHero';
import SideBySideSection from '../components/SideBySideSection';
import StatisticsSection from '../components/StatisticsSection';
import ResourcesSection from '../components/ResourcesSection';
import ProductsSection from '../components/ProductsSection';
import SiteMeta from '../components/SiteMeta';

const AboutPage = ({ data }) => {
  const {
    contentfulAboutPage: {
      metaTitle,
      metaDescription: { metaDescription },
      heroModule,
      aboutModule1,
      aboutModule2,
      aboutModule3,
      successSnapshotTitle,
      successSnapshotSubtitle,
      successSnapshotSubheader,
      successSnapshotStat1Number,
      successSnapshotStat1Text,
      successSnapshotStat2Number,
      successSnapshotStat2Text,
      successSnapshotStat3Number,
      successSnapshotStat3Text,
      featuredResourcesTitle,
      featuredResourcesSubheader: { featuredResourcesSubheader },
      featuredResourcesCtaText,
      featuredResourcesCtaLink,
      featuredResources,
      otherPwCProductsTitle,
      otherPwCProductsSubheader: { otherPwCProductsSubheader },
      otherPwCProducts,
    },
  } = data;

  const statisticsData = {
    title: successSnapshotTitle,
    subtitle: successSnapshotSubheader,
    statsHeader: successSnapshotSubtitle,
    items: [
      {
        itemTitle: successSnapshotStat1Text,
        value: successSnapshotStat1Number,
      },
      {
        itemTitle: successSnapshotStat2Text,
        value: successSnapshotStat2Number,
      },
      {
        itemTitle: successSnapshotStat3Text,
        value: successSnapshotStat3Number,
      },
    ],
  };

  const resourcesSectionData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheader,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources,
  };

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <MediaHero {...heroModule} />
      <SideBySideSection {...aboutModule1} backgroundTheme="white" barTheme="transparent" />
      <SideBySideSection {...aboutModule2} layoutVariant={'mediaRight'} barTheme="yellow" />
      <StatisticsSection {...statisticsData} />
      <SideBySideSection backgroundTheme="grey" barTheme="transparent" {...aboutModule3} />
      <ResourcesSection {...resourcesSectionData} />
      <ProductsSection title={otherPwCProductsTitle} subtitle={otherPwCProductsSubheader} items={otherPwCProducts} />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    contentfulAboutPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroModule {
        ...Navigator
      }
      aboutModule1 {
        ...Navigator
      }
      aboutModule2 {
        ...Navigator
      }
      aboutModule3 {
        ...Navigator
      }
      successSnapshotTitle
      successSnapshotSubtitle
      successSnapshotSubheader {
        json
      }
      successSnapshotStat1Number
      successSnapshotStat1Text
      successSnapshotStat2Number
      successSnapshotStat2Text
      successSnapshotStat3Number
      successSnapshotStat3Text
      featuredResourcesTitle
      featuredResourcesSubheader {
        featuredResourcesSubheader
      }
      featuredResourcesCtaText
      featuredResourcesCtaLink
      featuredResources {
        ...ResourceCard
      }
      otherPwCProductsTitle
      otherPwCProductsSubheader {
        otherPwCProductsSubheader
      }
      otherPwCProducts {
        ctaText
        cardName
        ctaLink
        subheaderText
        image {
          fluid(quality: 80, toFormat: JPG) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
