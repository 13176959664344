import React from 'react';
import ProductsCarousel from '../ProductsCarousel/ProductsCarousel';
import SectionIntro from '../SectionIntro';

const ProductsSection = ({ title, subtitle, items }) => {
  return (
    <section className="products-section">
      <div className="products-section__intro">
        <SectionIntro theme="dark" title={title} subtitle={subtitle} />
      </div>
      <div className="products-section__body">
        <ProductsCarousel items={items} />
      </div>
    </section>
  );
};

export default ProductsSection;
